import React from 'react'
import Layout from '../components/layout'

const DownloadPage = ({ pageContext: { docs }}) => {
  return (
    <Layout>
      <main className="main">
        <section className="section bg-light-gray">
          <div className="container" style={{textAlign: "center"}}>
            <div className="heading-top">
              <h3>無料資料ダウンロード</h3>
              <p>まだダウンロードは完了していません</p>
              <span>ボタンをクリックしてダウンロードしてください。</span>
            </div>
            <div className="list">
              <ul className="d-md-flex justify-content-around">
                <li className="list__item">
                  <div classname="document">
                    <div className="document__wrap">
                      <div className="document__title">社内AIチャットボット<br />導入に失敗しないための100のチェックリスト</div>
                      <div className="document__img" style={{backgroundImage:`url(${docs.checkList.img})`}}></div>
                    </div>
                    <a href={docs.checkList.url} download>
                      <button className="btn-primary mt-3">資料をダウンロード</button>
                    </a>
                  </div>
                </li>
                <li className="list__item">
                  <div classname="document">
                    <div className="document__wrap">
                      <div className="document__title">社内AIチャットボット<br />導入準備用Q&A収集シート</div>
                      <div className="document__img" style={{backgroundImage:`url(${docs.collectionSheet.img})`}}></div>
                    </div>
                    <a href={docs.collectionSheet.url} download>
                      <button className="btn-primary mt-3">資料をダウンロード</button>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default DownloadPage
